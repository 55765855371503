import React, { memo, FC, useState } from "react";
import { CkModal, CkIcon } from "../../../../CkUI";
import { WheelLoader } from "../../../../app/Components/Loaders";
import { Button } from "antd";
import { DataType } from "..";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";

import "./styles.css";
import { Appointment } from "shared";

interface CsvColumns {
  ID: string;
  "Cliente CarKer": "Si" | "No" | "";
  Nombre: string;
  Apellido: string;
  "Teléfono Celular": string;
  "Correo electrónico": string;
  "Nombre contacto": string;
  "Apellido contacto": string;
  "Teléfono Celular contacto": string;
  "Correo electrónico contacto": string;
  "Tipo de vehiculo": string;
  Marca: string;
  Modelo: string;
  Año: string;
  Kilometraje: string;
  "Número de serie": string;
  Placa: string;
  "Tipo de combustible": string;
  "Último servicio": string;
  "Fecha ultimo servicio": string;
  "Codigo postal": string;
  "Fecha de nacimiento": string;
  "Dirección para recolección": string;
  Género: string;
  Notas: string;
  "Nombre del receptor": string;
  RFC: string;
  "Régimen fiscal": string;
  "Uso de CFDI": string;
  "Correo electrónico facturacion": string;
  "Código postal": string;
  "Fecha	Servicio": string;
  Vehiculo: string;
  "Placa servicio": string;
}

const empty_csv_row: CsvColumns = {
  ID: "",
  "Cliente CarKer": "",
  Nombre: "",
  Apellido: "",
  "Teléfono Celular": "",
  "Correo electrónico": "",
  // from contact
  "Nombre contacto": "",
  "Apellido contacto": "",
  "Teléfono Celular contacto": "",
  "Correo electrónico contacto": "",
  // end of - from vehicle
  // from vehicle
  "Tipo de vehiculo": "",
  Marca: "",
  Modelo: "",
  Año: "",
  Kilometraje: "",
  "Número de serie": "",
  Placa: "",
  "Tipo de combustible": "",
  "Último servicio": "",
  "Fecha ultimo servicio": "",
  // end of - from vehicle
  "Codigo postal": "",
  "Fecha de nacimiento": "",
  "Dirección para recolección": "",
  Género: "",
  Notas: "",
  "Nombre del receptor": "",
  RFC: "",
  "Régimen fiscal": "",
  "Uso de CFDI": "",
  "Correo electrónico facturacion": "",
  // from appointments
  "Código postal": "",
  "Fecha	Servicio": "",
  Vehiculo: "",
  "Placa servicio": "",
  // end of - from appointments
};

const dataTypeVcsvColumn = {
  customerId: "ID",
  customerEmail: "Correo electrónico",
  customerName: "Nombre",
  customerLastName: "Apellido",
  customerTel: "Teléfono Celular",
  customerZipCode: "Codigo postal",
  customerDateOfBirth: "Fecha de nacimiento",
  customerSex: "Género",
  customerAddress: "Dirección para recolección",
  customerNotes: "Notas",
  customerReceptorName: "Nombre del receptor",
  customerRFC: "RFC",
  customerTaxRegime: "Régimen fiscal",
  customerCFDI: "Uso de CFDI",
  customerTaxZipCode: "ódigo postal",
  customerTaxEmail: "Correo electrónico facturacion",
  isCarkerClient: "Cliente CarKer",
  lastAppointment: "Último servicio",
};

const contactDataTypeVcsvColumn = {
  customerContactName: "Nombre contacto",
  customerContactLastName: "Apellido contacto",
  customerContactTel: "Teléfono Celular contacto",
  customerContactEmail: "Correo electrónico contacto",
};

const vehicleDataTypeVcsvColumn = {
  endConsumerVehicleType: "Tipo de vehiculo",
  endConsumerVehicleYear: "Año",
  endConsumerVehicleBrand: "Marca",
  endConsumerVehicleModel: "Modelo",
  endConsumerVehicleFuelType: "Tipo de combustible",
  endConsumerVehicleLicensePlate: "Placa",
  endConsumerVehicleMileage: "Kilometraje",
};
/* 
"customerVehicles": [],
"contacts": [],
 */
interface IProps {
  showExportModal: boolean;
  setShowExportModal: Function;
  data: DataType[];
}

const extractServiceName = (appointment: Appointment): string => {
  let name = "";
  const serviceName =
    appointment.services.length > 0
      ? appointment.services[0].serviceTypeName
      : null;
  const packageName =
    appointment.packages.length > 0
      ? appointment.packages[0].servicePackageDesc
      : null;
  name = serviceName ? serviceName : packageName;
  return name;
};

const formatData = (data: DataType[]): CsvColumns[] => {
  const outputArray: CsvColumns[] = [];
  data
    .filter((contact) => contact.customerId !== undefined)
    .forEach((contact) => {
      const greaterLength = [
        (contact.appointments || []).length,
        (contact.contacts || []).length,
        (contact.customerVehicles || []).length,
      ]
        .sort((a, b) => a - b)
        .pop();
      
      if (greaterLength === 0) {
        let newRow = { ...empty_csv_row };
        // Loop non array data
        Object.keys(dataTypeVcsvColumn).forEach((key) => {
          if (key === "isCarkerClient") {
            newRow[dataTypeVcsvColumn[key]] = contact[key] ? "Si" : "No";
          } 
          if (key === "lastAppointment" && !!contact[key] !== false) {
            newRow[dataTypeVcsvColumn[key]] = extractServiceName(contact[key]);
          } else if (!!contact[key] !== false) {
            newRow[dataTypeVcsvColumn[key]] = contact[key];
          }
        });
        outputArray.push(newRow);
      }
      // Generate new row
      if (greaterLength > 0) {
        for (let i = 0; i < greaterLength; i++) {
          let newRow = { ...empty_csv_row };
          // Loop non array data
          Object.keys(dataTypeVcsvColumn).forEach((key) => {
            if (key === "isCarkerClient") {
              newRow[dataTypeVcsvColumn[key]] = contact[key] ? "Si" : "No";
            } 
            if (key === "lastAppointment" && !!contact[key] !== false) {
              newRow[dataTypeVcsvColumn[key]] = extractServiceName(contact[key]);
            } else if (!!contact[key] !== false) {
              newRow[dataTypeVcsvColumn[key]] = contact[key];
            }
          });
          if (contact.contacts[i]) {
            Object.keys(contactDataTypeVcsvColumn).forEach((key) => {
              if (!!contact.contacts[i][key] !== false) {
                newRow[contactDataTypeVcsvColumn[key]] =
                  contact.contacts[i][key];
              }
            });
          }
          if (contact.customerVehicles[i]) {
            Object.keys(vehicleDataTypeVcsvColumn).forEach((key) => {
              if (!!contact.customerVehicles[i][key] !== false) {
                newRow[vehicleDataTypeVcsvColumn[key]] =
                  contact.customerVehicles[i][key];
              }
            });
          }
          outputArray.push(newRow);
        }
      }
    });

  return outputArray;
};

const exportToCSV = (data: DataType[], isLoading: Function): void => {
  isLoading(true);
  // Create a new workbook and add a worksheet
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(formatData(data));

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Customers");

  // Generate a CSV buffer
  const csvBuffer = XLSX.write(workbook, { bookType: "csv", type: "array" });

  // Create a Blob from the CSV buffer
  const blob = new Blob([csvBuffer], { type: "text/csv;charset=utf-8;" });

  // Use FileSaver to save the CSV file
  saveAs(blob, `clientes-${new Date().toISOString()}.csv`);
  isLoading(false);
};

const exportToPdf = (data: DataType[], isLoading: Function) => {
  isLoading(true);

  const formatedData = formatData(data);
  // Create a new jsPDF instance
  const doc = new jsPDF({
    orientation: "landscape",
  });

  // Define the columns for the table
  const columns = Object.keys(formatedData[0]).map((key) => ({
    title: key,
    dataKey: key,
  }));

  // Add the table to the PDF
  doc.autoTable({
    columns: columns,
    body: formatedData,
    styles: { overflow: "linebreak" }, // Ensures text does not overflow the cell boundaries
    theme: "grid",
    pageBreak: "auto",
    horizontalPageBreak: true,
    horizontalPageBreakRepeat: 0,
    horizontalPageBreakBehaviour: "immediately",
  });

  // Save the PDF
  doc.save(`clientes-${new Date().toISOString()}.pdf`);
  isLoading(false);
};

const ExportClientsList: FC<IProps> = ({
  showExportModal,
  setShowExportModal,
  data,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <CkModal
      className="export-client-list-modal"
      open={showExportModal}
      onCancel={() => setShowExportModal(false)}
      secondaryAction={{
        label: "Cancelar",
        onClick: () => {
          setShowExportModal(false);
        },
        type: "link",
      }}
      title="Exportar"
      actionButtonsDirection={"column"}
      zIndex={5000}
    >
      <p>Elige el formato en el que quieres exportar tu lista de clientes.</p>

      <div className="buttons-container">
        <Button
          onClick={() => {
            exportToCSV(data, setLoading);
          }}
        >
          <CkIcon name="csv" />
          <p>CSV</p>
          <p>Para Excel, hojas de cálculo, importar a otras plataformas</p>
        </Button>

        <Button
          onClick={() => {
            exportToPdf(data, setLoading);
          }}
        >
          <CkIcon name="pdf" />
          <p>PDF</p>
          <p>Para visualización o impresión</p>
        </Button>
      </div>
      <WheelLoader mode="cover-container" spinning={loading} />
    </CkModal>
  );
};

export default memo(ExportClientsList);
