import React, { Fragment, memo } from "react";

interface IProps {
  itemName: string,
  item: string,
}
const NamedCardItem: React.FC<IProps> = ({itemName, item  }: IProps) => {

  return (
    <Fragment>
      <p style={{ fontSize: "14px", color: "#6D6E71", margin: 0 }}>{itemName}</p>
      <p style={{ fontSize: "16px", color: "#444444"}}>{item}</p>
    </Fragment>
  );
};

export default memo(NamedCardItem);
