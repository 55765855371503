import React, { Fragment, memo, useEffect, useState } from "react";
import {Col, InputNumber, Row, Slider} from "antd";
import FilterItem from "./FilterItem";
import {CkButton, CkInput, CkSelect} from "../../../../CkUI";
import * as CatalogAPI from "shared/src/services/catalogsAPI";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentWorkshop, getCustomerListFilterData} from "../../../../stores";
import type { SliderMarks } from 'antd/es/slider';
import {updateCustomerListFilterData} from "../../../../stores/Slice/NewCustomerFormSlice";

interface Tag {
  tagName: string;
  tagId: string;
}
interface IProps {
  tags: Tag[],
  onFilterSave: Function,
  onFilterReset: Function,
}
const marks: SliderMarks = {
  1900: '1900',
  [new Date().getFullYear()]: (new Date().getFullYear()).toString(),
};
const ClientListFilter: React.FC<IProps> = ({ tags, onFilterSave, onFilterReset }: IProps) => {
  const dispatch = useDispatch();
  const currentWorkshop: any = useSelector(getCurrentWorkshop);
  const filterData = useSelector(getCustomerListFilterData);
  const [vehicleBrands, setVehicleBrands] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [year, setYear] = useState(0);
  const [yearStart, setYearStart] = useState(0);

  useEffect(() => {
    if(filterData.vehicleType) {
      getBrands();
    }
  }, [filterData.vehicleType]);

  useEffect(() => {
    setYear(Number(filterData.vehicleYear));
  }, [filterData.vehicleYear]);

  useEffect(() => {
    setYearStart(Number(filterData.vehicleYearStart));
  }, [filterData.vehicleYearStart]);

  useEffect(() => {
    if(currentWorkshop) {
      const uniquePackageList: string[] = [];
      const serviceList = [];//currentWorkshop.typeCodes;
      currentWorkshop.packages.map((packageItem) => {
        if(!uniquePackageList.includes(packageItem?.servicePackageSet?.servicePackage?.servicePackageDesc)) {
          uniquePackageList.push(packageItem?.servicePackageSet?.servicePackage?.servicePackageDesc);
        }
      });
      setServicesList([...serviceList, ...uniquePackageList]);
    }
  }, [currentWorkshop]);

  const onYearChange = (newValue: number[]) => {
    setYear(newValue[1]);
    setYearStart(newValue[0]);
  }

  const getBrands = () => {
    const otherBrand: any = { code: "custom_brand", desc: "Otra marca" };
    CatalogAPI.fetchVehicleBrands(filterData.vehicleType).then((values) => {
      let allBrands = values.data;
      if (allBrands.filter((x) => x.code === "custom_brand")?.length === 0)
        allBrands.push(otherBrand);
      setVehicleBrands(allBrands);
    });
  }

  const onLastServiceTimeClick = (id) => {
    //setLastServiceTime(id);
    dispatch(updateCustomerListFilterData({ lastServiceDate: id }))
  }

  const onFilterTagClicked = (id) => {
    if(filterData.tags.includes(id)){
      const filterTag = filterData.tags.filter((tagId) => tagId !== id);
      //setSelectedTags(filterTag);
      dispatch(updateCustomerListFilterData({ tags: filterTag}))
    } else {
      //setSelectedTags([...selectedTags, id]);
      dispatch(updateCustomerListFilterData({ tags: [...filterData.tags, id]}))
    }
  }

  return (
    <Fragment>
      <p style={{ color: "#2369BD", fontSize: "24px", fontWeight: "600"}}>
        Filtrar por:
      </p>

      <Row>
        <div style={{ minWidth: "30%" }}>
          <p style={{ fontSize: "16px", fontWeight: 600 }}>
            Fecha último servicio:
          </p>
        </div>
        <div style={{ width: "70%", display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
          <FilterItem text="Último mes" selected={filterData.lastServiceDate === "last-month"} onClose={null} id="last-month" onFilterClick={onLastServiceTimeClick}/>
          <FilterItem text="Últimos 3 meses" selected={filterData.lastServiceDate === "last-3-months"} onClose={null} id="last-3-months" onFilterClick={onLastServiceTimeClick}/>
          <FilterItem text="Últimos 6 meses" selected={filterData.lastServiceDate === "last-6-months"} onClose={null} id="last-6-months" onFilterClick={onLastServiceTimeClick}/>
          <FilterItem text="Último año" selected={filterData.lastServiceDate === "last-year"} onClose={null} id="last-year" onFilterClick={onLastServiceTimeClick}/>
        </div>
      </Row>
      <Row>
        <div style={{width: "30%"}}>
          <p style={{ fontSize: "16px", fontWeight: 600 }}>
            Tipo de vehículo:
          </p>
        </div>
        <div style={{width: "70%", display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
          <FilterItem text="Automóvil" selected={filterData.vehicleType === "AUTOS"} onClose={null} id="AUTOS" onFilterClick={(id) => dispatch(updateCustomerListFilterData({vehicleType: id})) } />
          <FilterItem text="Moto" selected={filterData.vehicleType === "MOTOCICLETA"} onClose={null} id="MOTOCICLETA" onFilterClick={(id) => dispatch(updateCustomerListFilterData({vehicleType: id}))}/>
          <FilterItem text="Camión" selected={filterData.vehicleType === "VEHICULOS DE CARGA LIGERA"} onClose={null} id="VEHICULOS DE CARGA LIGERA" onFilterClick={(id) => dispatch(updateCustomerListFilterData({vehicleType: id}))}/>
        </div>
      </Row>
      <Row>
        <div style={{width: "30%"}}>
          <p style={{ fontSize: "16px", fontWeight: 600 }}>
            Marca de vehículos:
          </p>
        </div>
        <div style={{width: "70%"}}>
          <CkSelect
            inputProps={{
              disabled: vehicleBrands.length === 0,
              value: filterData.vehicleBrand,
              placeholder: "Marca",
              dropdownStyle: { zIndex: 5000 },
              onChange: (e) => {
                //dispatch(updateVehicleFormData({ endConsumerVehicleBrand: e }))
                dispatch(updateCustomerListFilterData({ vehicleBrand: e}))
              },
              options: vehicleBrands?.map((brand: any, index) => {
                return {
                  value: brand.code,
                  label: brand.code === "custom_brand"
                    ? "Otra marca"
                    : brand.desc,
                };
              }),
            }}
          />
        </div>
      </Row>
      <Row>
        <div style={{width: "30%"}}>
          <p style={{ fontSize: "16px", fontWeight: 600 }}>
            Terminación de placas:
          </p>
        </div>
        <div style={{width: "70%"}}>
          <CkInput
            inputProps={{
              maxLength: 1,
              value: filterData.vehicleLicenceTermination,
              onChange: (e) => { dispatch(updateCustomerListFilterData({ vehicleLicenceTermination: e.target.value}))}
            }}
          />
        </div>
      </Row>
      <Row>
        <div style={{width: "30%"}}>
          <p style={{ fontSize: "16px", fontWeight: 600 }}>
            Año del vehículo:
          </p>
        </div>
        <div style={{width: "70%"}}>
          <Row>
            <Col span={16}>
              <Slider
                range
                min={1900}
                max={new Date().getFullYear()}
                marks={marks}
                onChange={onYearChange}
              />
            </Col>
            <Col span={8}>
              <div style={{ display: "flex", flexDirection: "row"}}>
                <InputNumber
                  style={{ margin: "0 10px 0 5px"}}
                  value={yearStart}
                />
                <InputNumber
                  style={{ margin: "0 10px 0 5px"}}
                  value={year}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Row>
      <Row>
        <div style={{width: "30%"}}>
          <p style={{ fontSize: "16px", fontWeight: 600 }}>
            Servicios realizados:
          </p>
        </div>
        <div style={{width: "70%"}}>
          <CkSelect
            inputProps={{
              value: filterData.services,
              placeholder: "",
              dropdownStyle: { zIndex: 5000 },
              onChange: (e) => {
                //dispatch(updateVehicleFormData({ endConsumerVehicleBrand: e }))
                dispatch(updateCustomerListFilterData({ services: e }))
              },
              options: servicesList?.map((serv: any, index) => {
                return {
                  value: serv,
                  label: serv,
                };
              }),
            }}
          />
        </div>
      </Row>
      <Row>
        <div style={{width: "30%"}}>
          <p style={{ fontSize: "16px", fontWeight: 600 }}>
            Etiquetas:
          </p>
        </div>
        <div style={{width: "70%", display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
          {
            tags?.map(tag => (
              <FilterItem
                text={tag.tagName}
                //selected={selectedTags.includes(tag.tagId)}
                selected={filterData.tags.includes(tag.tagId)}
                onClose={null}
                id={tag.tagId}
                onFilterClick={onFilterTagClicked}
              />
            ))
          }
        </div>
      </Row>

      <Row justify="center">
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "30px"}}>
          <CkButton
            type="default"
            onClick={()=>{
              if(year > 0) {
                dispatch(updateCustomerListFilterData({ vehicleYear: year.toString(), vehicleYearStart: yearStart.toString() }));
              }
              onFilterSave();
            }}
          >
            Aplicar filtros
          </CkButton>
          <CkButton
            style={{ marginTop: "10px" }}
            type="text"
            onClick={() => {
              onFilterReset();
            }}
          >
            Borrar filtros
          </CkButton>
        </div>
      </Row>
    </Fragment>
  );
};

export default memo(ClientListFilter);
