import React, { useState, FC, memo, useEffect } from "react";
import { CkModal, CkButton, CkIcon, CkMessage } from "../../../../CkUI";
import { Upload } from "antd";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import { read, utils } from "xlsx";
import { set_cptable } from "xlsx";
import * as cptable from "xlsx/dist/cpexcel.full.mjs";
import {
  CustomerFormData,
  ContactData,
  VehicleFormData,
  AppointmentFormData,
} from "../../../../stores/Slice/NewCustomerFormSlice";
import { WheelLoader } from "../../../../app/Components/Loaders";
import { ClientsAPI } from "../../../../app/Services/axios";
import "./styles.css";
import { useSelector } from "react-redux";
import { getWorkshops, getWorkshopSelected } from "../../../../stores";
set_cptable(cptable);

interface IdedCustomerFormData extends CustomerFormData {
  compareId: string;
}

interface IProps {
  showImportModal: boolean;
  setShowImportModal: Function;
  onFinish: Function;
}

interface ExcelRowData {
  __EMPTY?: string;
  __EMPTY_1?: string;
  __EMPTY_2?: string;
  __EMPTY_3?: string;
  __EMPTY_5?: string;
  __EMPTY_6?: string;
  __EMPTY_7?: string;
  __EMPTY_8?: string;
  __EMPTY_10?: string;
  __EMPTY_11?: string;
  __EMPTY_12?: string;
  __EMPTY_13?: string;
  __EMPTY_14?: string;
  __EMPTY_15?: string;
  __EMPTY_16?: string;
  __EMPTY_17?: string;
  __EMPTY_18?: string;
  __EMPTY_20?: string;
  __EMPTY_21?: string;
  __EMPTY_22?: string;
  __EMPTY_23?: string;
  __EMPTY_24?: string;
  __EMPTY_26?: string;
  __EMPTY_27?: string;
  __EMPTY_28?: string;
  __EMPTY_29?: string;
  __EMPTY_30?: string;
  __EMPTY_31?: string;
  __EMPTY_33?: string;
  __EMPTY_34?: string;
  __EMPTY_35?: string;
  __EMPTY_36?: string;
}

const CUSTOMER_DATA_EXCEL_KEYS = {
  __EMPTY: {
    label: "Nombre",
    value: "customerName",
  },
  __EMPTY_1: {
    label: "Apellido",
    value: "customerLastName",
  },
  __EMPTY_2: {
    label: "Teléfono Celular",
    value: "customerTel",
  },
  __EMPTY_3: {
    label: "Correo electrónico",
    value: "customerEmail",
  },
};

const CONTACT_DATA_EXCEL_KEYS = {
  __EMPTY_5: {
    label: "Nombre",
    value: "customerContactName",
  },
  __EMPTY_6: {
    label: "Apellido",
    value: "customerContactLastName",
  },
  __EMPTY_7: {
    label: "Teléfono Celular",
    value: "customerContactTel",
  },
  __EMPTY_8: {
    label: "Correo electrónico",
    value: "customerContactEmail",
  },
};

const VEHICLE_DATA_EXCEL_KEYS = {
  __EMPTY_10: {
    label: "Marca",
    value: "endConsumerVehicleBrand",
  },
  __EMPTY_11: {
    label: "Modelo",
    value: "endConsumerVehicleModel",
  },
  __EMPTY_12: {
    label: "Año",
    value: "endConsumerVehicleYear",
  },
  __EMPTY_13: {
    label: "Kilometraje",
    value: "endConsumerVehicleKm",
  },
  __EMPTY_14: {
    label: "Número de serie",
    value: "endConsumerVehicleSeriesNo",
  },
  __EMPTY_15: {
    label: "Placa",
    value: "endConsumerVehicleLicensePlate",
  },
  __EMPTY_16: {
    label: "Tipo de combustible",
    value: "endConsumerVehicleFuelType",
  },
  /* 
  __EMPTY_17: {
    label: "Último servicio",
    value: "",
  },
  __EMPTY_18: {
    label: "Fecha ultimo servicio",
    value: "",
  },
  */
};

const COMPLEMENTARY_DATA_EXCEL_KEYS = {
  __EMPTY_20: {
    label: "Codigo postal",
    value: "customerZipCode",
  },
  __EMPTY_21: {
    label: "Fecha de nacimiento",
    value: "customerDateOfBirth",
  },
  __EMPTY_22: {
    label: "Dirección para recolección",
    value: "customerAddress",
  },
  __EMPTY_23: {
    label: "Género",
    value: "customerSex",
  },
  __EMPTY_24: {
    label: "Notas",
    value: "customerNotes",
  },
};

const FISCAL_DATA_EXCEL_KEYS = {
  __EMPTY_26: {
    label: "Nombre del receptor",
    value: "customerReceptorName",
  },
  __EMPTY_27: {
    label: "RFC",
    value: "customerRFC",
  },
  __EMPTY_28: {
    label: "Régimen fiscal",
    value: "customerTaxRegimen",
  },
  __EMPTY_29: {
    label: "Uso de CFDI",
    value: "customerCFDI",
  },
  __EMPTY_30: {
    label: "Correo electrónico",
    value: "customerTaxEmail",
  },
  __EMPTY_31: {
    label: "Código postal",
    value: "customerTaxZipCode",
  },
};

const APPOINTMENTS_DATA_EXCEL_KEYS = {
  __EMPTY_33: {
    label: "Fecha",
    value: "service",
  },
  __EMPTY_34: {
    label: "Servicio",
    value: "date",
  },
  __EMPTY_35: {
    label: "Vehiculo",
    value: "vehicle",
  },
  __EMPTY_36: {
    label: "Placa",
    value: "",
  },
};

const EMPTY_CUSTOMER: CustomerFormData = {
  customerName: "",
  customerLastName: "",
  customerTel: "",
  customerEmail: "",
  contacts: [],
  customerZipCode: "",
  customerDateOfBirth: "",
  customerSex: "",
  customerAddress: "",
  customerNotes: "",
  customerReceptorName: "",
  customerRFC: "",
  customerTaxRegimen: "",
  customerCFDI: "",
  customerTaxZipCode: "",
  customerTaxEmail: "",
  customerTags: [],
  customerVehicles: [],
  appointmentHistory: [],
  appointments: []
};

const EMPTY_CONTACT: ContactData = {
  customerContactName: "",
  customerContactLastName: "",
  customerContactTel: "",
  customerContactEmail: "",
};

const EMPTY_VEHICLE: VehicleFormData = {
  endConsumerVehicleModelCode: "",
  endConsumerVehicleType: "",
  endConsumerVehicleYear: 0,
  endConsumerVehicleBrand: "",
  endConsumerVehicleModel: "",
  endConsumerVehicleFuelType: "",
  isCustomBrand: false,
  isCustomModel: false,
  endConsumerVehicleLicensePlate: "",
  endConsumerVehicleSeriesNo: "",
  endConsumerVehicleMainPurposeCode: "",
  endConsumerVehicleKm: "",
};

const EMPTY_APPOINTMENT = {
  service: "",
  date: "",
  vehicle: "",
};
interface checkRowOutput {
  compareId: string;
  customerData: CustomerFormData;
  contactData?: ContactData;
  vehicleData?: VehicleFormData;
  appointmentData?: AppointmentFormData;
}

const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, add one.
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const removeNotDataRows = (data: ExcelRowData[]): ExcelRowData[] => {
  // Find the index of the object where __EMPTY is 'Nombre'
  const index = data.findIndex((obj) => obj.__EMPTY === "Nombre");

  // If 'Nombre' is found, return a new array starting from the next index
  // If not found, findIndex returns -1, and we return an empty array
  return index >= 0 ? data.slice(index + 1) : [];
};

const extractNumbers = (input: string | number): string => {
  // This regular expression matches all digits in the string
  
  const matches = input.toLocaleString().match(/\d+/g);

  // If the input contains numbers, join them together; otherwise, return an empty string
  return matches ? matches.join("") : "";
};

const checkRow = (row: ExcelRowData): checkRowOutput => {
  const customerData = {
    ...EMPTY_CUSTOMER,
  };
  const contactData = {
    ...EMPTY_CONTACT,
  };
  const vehicleData = {
    ...EMPTY_VEHICLE,
  };
  const appointmentData = {
    ...EMPTY_APPOINTMENT,
  };

  // Set customer data
  Object.keys(CUSTOMER_DATA_EXCEL_KEYS).forEach((currentKey) => {
    if (row[currentKey] !== undefined) {
      if (CUSTOMER_DATA_EXCEL_KEYS[currentKey].value === "customerTel") {
        customerData[CUSTOMER_DATA_EXCEL_KEYS[currentKey].value] =
          extractNumbers(row[currentKey]);
      } else {
        customerData[CUSTOMER_DATA_EXCEL_KEYS[currentKey].value] =
          typeof row[currentKey] === "string"
            ? row[currentKey].trim()
            : row[currentKey];
      }
    }
  });

  // Set complementary data
  Object.keys(COMPLEMENTARY_DATA_EXCEL_KEYS).forEach((currentKey) => {
    if (row[currentKey] !== undefined) {
      if (COMPLEMENTARY_DATA_EXCEL_KEYS[currentKey].value === "customerDateOfBirth") {
        customerData[COMPLEMENTARY_DATA_EXCEL_KEYS[currentKey].value] = formatDate(
          row[currentKey]
        );
      } else {
        customerData[COMPLEMENTARY_DATA_EXCEL_KEYS[currentKey].value] =
        typeof row[currentKey] === "string"
          ? row[currentKey].trim()
          : row[currentKey];
      }
    }
  });

  // Set complementary data
  Object.keys(FISCAL_DATA_EXCEL_KEYS).forEach((currentKey) => {
    if (row[currentKey] !== undefined) {
      customerData[FISCAL_DATA_EXCEL_KEYS[currentKey].value] =
        typeof row[currentKey] === "string"
          ? row[currentKey].trim()
          : row[currentKey];
    }
  });

  // set contact data
  Object.keys(CONTACT_DATA_EXCEL_KEYS).forEach((currentKey) => {
    if (row[currentKey] !== undefined) {
      if (CONTACT_DATA_EXCEL_KEYS[currentKey].value === "customerTel") {
        contactData[CONTACT_DATA_EXCEL_KEYS[currentKey].value] = extractNumbers(
          row[currentKey]
        );
      } else {
        contactData[CONTACT_DATA_EXCEL_KEYS[currentKey].value] =
          typeof row[currentKey] === "string"
            ? row[currentKey].trim()
            : row[currentKey];
      }
    }
  });

  // Set vehicle data
  let haveSustitution = false;
  Object.keys(VEHICLE_DATA_EXCEL_KEYS).forEach((currentKey) => {
    if (row[currentKey] !== undefined) {
      haveSustitution = true;
      vehicleData[VEHICLE_DATA_EXCEL_KEYS[currentKey].value] =
        typeof row[currentKey] === "string"
          ? row[currentKey].trim()
          : row[currentKey];
    }
  });
  if (haveSustitution) {
    vehicleData.isCustomBrand = true;
    vehicleData.isCustomModel = true;
  }

  // Set appointments data
  Object.keys(APPOINTMENTS_DATA_EXCEL_KEYS).forEach((currentKey) => {
    if (row[currentKey] !== undefined) {
      appointmentData[APPOINTMENTS_DATA_EXCEL_KEYS[currentKey].value] =
        typeof row[currentKey] === "string"
          ? row[currentKey].trim()
          : row[currentKey];
    }
  });

  const id =
    `${customerData.customerName}-${customerData.customerLastName}-${customerData.customerTel}-${customerData.customerEmail}`.toLowerCase();
  
  return {
    compareId: id,
    customerData,
    ...(Object.keys(contactData).some((key) => !!contactData[key] === true)
      ? { contactData }
      : {}),
    ...(Object.keys(vehicleData).some((key) => !!vehicleData[key] === true)
      ? { vehicleData }
      : {}),
    ...(Object.keys(appointmentData).some(
      (key) => !!appointmentData[key] === true
    )
      ? { appointmentData }
      : {}),
  };
};

const mergeRows = (
  checkedRow: checkRowOutput,
  mergedData: IdedCustomerFormData[]
): IdedCustomerFormData[] => {
  const checkedRowIndex = mergedData.findIndex(
    (data) => data.compareId === checkedRow.compareId
  );
  const newMergedData = [...mergedData];
  // Check if exist
  if (checkedRowIndex !== -1) {
    checkedRow.contactData &&
      newMergedData[checkedRowIndex].contacts.push(
        checkedRow.contactData
      );
    checkedRow.vehicleData &&
      newMergedData[checkedRowIndex].customerVehicles.push(
        checkedRow.vehicleData
      );
    checkedRow.appointmentData &&
      newMergedData[checkedRowIndex].appointmentHistory.push(
        checkedRow.appointmentData
      );
    // Else pop
  } else {
    newMergedData.push({
      compareId: checkedRow.compareId,
      ...checkedRow.customerData,
      ...(checkedRow.contactData
        ? { contacts: [checkedRow.contactData] }
        : {}),
      ...(checkedRow.vehicleData
        ? { customerVehicles: [checkedRow.vehicleData] }
        : {}),
      ...(checkedRow.appointmentData
        ? { appointmentHistory: [checkedRow.appointmentData] }
        : {}),
    });
  }
  return newMergedData;
};

const ImportClientsList: FC<IProps> = ({
  showImportModal,
  setShowImportModal,
  onFinish,
}) => {
  const [customerData, setCustomerData] = useState<CustomerFormData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const selectedWorkshop = useSelector(getWorkshopSelected);
  const workshops = useSelector(getWorkshops);

  // Handle file reading on change
  const onFileChange = (info: UploadChangeParam) => {
    setLoading(true);
    setCustomerData([]);

    const file = info.file as RcFile;

    // Ensure file is selected
    if (!file) {
      setLoading(false);
      CkMessage({
        type: "error",
        text: `El archivo seleccionado no tiene datos para importar.`,
        zIndex: 6001,
      });
      return;
    }

    // FileReader to read file
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const data = e.target?.result;
      const workbook = read(data, { type: 'array', cellDates: true }); // Parse the file

      // Process the first worksheet
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const jsonData = utils.sheet_to_json(worksheet);

      // Search for the first row
      const rawData = removeNotDataRows(jsonData);
      if (rawData.length === 0) {
        setLoading(false);
        CkMessage({
          type: "error",
          text: `El archivo seleccionado no tiene datos para importar.`,
          zIndex: 6001,
        });
        return;
      }
      // Check each row
      const mergedData: CustomerFormData[] = rawData
        .reduce((prevArray, current) => {
          const checkedData = checkRow(current);
          return mergeRows(checkedData, prevArray);
        }, [])
        .map((current: IdedCustomerFormData) => {
          delete current.compareId;
          return current;
        });
      setCustomerData(mergedData);
      setLoading(false);
    };
    reader.onerror = (event) => {
      CkMessage({
        type: "error",
        text: `Se produjo un error al leer el archivo: ${event.type}`,
        zIndex: 6001,
      });
    };

    // Read the file
    reader.readAsArrayBuffer(file);
  };

  const importNewCustomers = async () => {
    console.info("customerData", customerData);
    setLoading(true);
    const currentWorkshop = workshops[selectedWorkshop];
    const importRequest = await ClientsAPI.addClients(currentWorkshop.id, customerData).then((response) => response.data).catch((e) => e);
    console.info("importRequest", importRequest);
    CkMessage({
      type: "success",
      text: "Usuarios importados exitosamente",
    });
    setCustomerData([]);
    setLoading(false);
    setShowImportModal(false);
    onFinish && onFinish();
    /* setLoading(true);
    const currentWorkshop = workshops[selectedWorkshop];
    const importsResult = await Promise.all(
      customerData.map(async (customer) => {
        return await ClientsAPI.addClient(currentWorkshop.id, customer).then((response) => response.data).catch((e) => e);
      })
    );
    CkMessage({
      type: "success",
      text: "Usuarios importados exitosamente",
    });
    setCustomerData([]);
    setLoading(false);
    setShowImportModal(false);
    onFinish && onFinish(); */
  };

  return (
    <CkModal
      className="import-client-list-modal"
      open={showImportModal}
      onCancel={() => setShowImportModal(false)}
      primaryAction={{
        label: "Importar lista de clientes",
        disabled: customerData.length === 0,
        onClick: importNewCustomers,
      }}
      secondaryAction={{
        label: "Cancelar",
        onClick: () => {
          setShowImportModal(false);
        },
        type: "link",
        color: "danger",
      }}
      actionButtonsDirection={"column"}
      zIndex={5000}
      title={"Importar lista de clientes"}
    >
      <div className="notice">
        <CkIcon name="information-round" />
        <p>
          Para poder importar un archivo existente de base de datos de clientes,
          es importante que se use el formato definido para asegurar la
          importación correcta de los datos.
        </p>
      </div>

      <ol>
        <li>Descarga el formato proporcionado</li>
        <CkButton
          href={process.env.PUBLIC_URL + "/Clientes.xlsx"}
          download="Clientes.xlsx"
          type="link"
          style={{
            margin: "0 auto",
            display: "block",
          }}
        >
          Descargar formato lista de clientes
        </CkButton>
        <li>
          Copia o importa los datos que tengas en las columnas correspondientes
        </li>
        <li>Guarda los cambios realizados.</li>
        <li>Adjunta el formato llenado abajo.</li>
      </ol>

      <div className="attachment-button-container">
        {customerData.length > 0 && (
          <p className="rows-founded">
            Se encontraron {customerData.length} clientes únicos en el archivo.
          </p>
        )}
        <Upload
          accept=".xlsx,.xls"
          showUploadList={false}
          beforeUpload={() => false} // Disable auto-uploading
          onChange={onFileChange}
        >
          <CkButton
            icon="attach"
            type="primary"
            style={{ width: "fit-content" }}
          >
            Adjuntar formato de lista de cliente
          </CkButton>
        </Upload>
      </div>
      <WheelLoader mode="cover-container" spinning={loading} />
    </CkModal>
  );
};

export default memo(ImportClientsList);
