import React, { memo, Fragment, useState, useEffect } from "react";
import { Checkbox, Col, Dropdown, Input, Popover, Row, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import LetterDirectory from "./LetterDirectory";
import type { MenuProps } from "antd";
import "./style.css";
import { CkButton, CkModal } from "../../../CkUI/components/molecules";
import CkIcon from "../../../CkUI/components/atoms/CkIcon";

import ClientListFilter from "./ClientListFilter";
import ImportClientsList from "./ImportClientsList";
import ExportClientsList from "./ExportClientsList";
import moment from "moment";
import AddNewTagForm from "../AddClientDrawer/ClientTagsForm/AddNewTagForm";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentWorkshopTags } from "../../../stores";
import { ClientsAPI } from "../../../app/Services/axios";
import {
  addNewWorkshopTag,
  resetCustomerListFilter,
  updateCustomerListFilterData,
  ContactData
} from "../../../stores/Slice/NewCustomerFormSlice";
import { Appointment } from "shared";

interface IProps {
  currentWorkshopId: string;
  data: DataType[];
  setOpenClientCard: Function;
  searchTerm: string;
  setSearchTerm: Function;
  setSelectedCustomer: Function;
  selectedCustomer: any;
  getClients: Function;
}

interface Tag {
  tagName: string;
  tagId: string;
}
export interface DataType {
  key: string;
  customerName: string;
  customerLastName: string;
  appointments: string;
  customerTel: string;
  customerVehicles: any[];
  lastAppointment: Appointment;
  customerTags: Tag[];
  isHeader: boolean;
  customerId: number;
  isCarkerClient: boolean;
  contacts: ContactData[];
}
const getColor = (status: string) => {
  switch (status) {
    case "Cita pendiente":
      return "gold";
    case "Cita en progreso":
      return "green";
    case "Recordatorio enviado":
      return "blue";
    case "Sin cita activa":
      return "default";
    case "Cita cancelada":
      return "red";
    default:
      return "default";
  }
};

const getStatus = (lastAppointment) => {
  switch (lastAppointment?.statusCode || "") {
    case "CREAT":
    case "APPO":
    case "RECEP":
    case "DIAG":
    case "QUOT":
    case "PROG":
    case "READY":
      return "Cita en progreso";
    case "PEND":
      return "Cita pendiente";
    case "CLOS":
      return "Sin cita activa";
    case "CANCE":
      return "Cita cancelada";
    default:
      return "Sin cita activa";
  }
};

const getHowLongAgo = (date: string) => {
  const fromNow = moment(date).fromNow();
  return fromNow[0].toUpperCase() + fromNow.slice(1);
};

const getColumns = (
  setOpenCardDrawer,
  setOpenTagModal,
  setSelectedCustomer,
  setOpenDeleteModal
) => {
  const columns: ColumnsType<DataType> = [
    {
      title: "Cliente",
      dataIndex: "customerLastName",
      key: "customerLastName",
      render: (item, row) => {
        return row.isHeader ? (
          <p
            id={`header-${row.customerName}`}
            style={{ fontSize: "16px", fontWeight: "600", color: "#2369BD" }}
          >
            {row.customerName}
          </p>
        ) : (
          <p
            className="name-field"
            onClick={() => {
              setOpenCardDrawer(true);
              setSelectedCustomer(row);
            }}
          >
            {`${item}, ${row.customerName}`}
          </p>
        );
      },
    },
    {
      title: "Estatus",
      dataIndex: "lastAppointment",
      key: "lastAppointment",
      render: (item, row) => {
        if (row.isHeader) return null;
        const status = getStatus(item);
        return <Tag color={getColor(status)}>{status}</Tag>;
      },
    },
    {
      title: "Teléfono",
      dataIndex: "customerTel",
      key: "customerTel",
    },
    {
      title: "Vehículos",
      dataIndex: "customerVehicles",
      key: "customerVehicles",
      render: (_, { customerVehicles }) => {
        const shownCars = customerVehicles?.slice(0, 2);
        const rest = customerVehicles?.slice(2);

        return (
          <Fragment>
            {shownCars &&
              shownCars.map((car) => {
                return (
                  <p style={{ margin: 0 }}>
                    {`${car.endConsumerVehicleBrand} ${car.endConsumerVehicleModel}`}
                  </p>
                );
              })}
            <Fragment>
              {rest && rest.length > 0 ? (
                <Popover
                  style={{ padding: "5px" }}
                  placement="bottomLeft"
                  content={
                    <Fragment>
                      {rest.map((car) => {
                        return (
                          <p
                            style={{ margin: "0 10px 0 10px" }}
                          >{`${car.endConsumerVehicleBrand} ${car.endConsumerVehicleModel}`}</p>
                        );
                      })}
                    </Fragment>
                  }
                >
                  <p
                    style={{
                      margin: 0,
                      textDecoration: "underline",
                      color: "#2369BD",
                      fontSize: "12px",
                    }}
                  >{`+${rest.length}`}</p>
                </Popover>
              ) : null}
            </Fragment>
          </Fragment>
        );
      },
    },
    {
      title: "Placas",
      dataIndex: "customerVehicles",
      key: "customerVehicles",
      render: (_, { customerVehicles }) => {
        const shownLicenses = customerVehicles?.slice(0, 2);
        const rest = customerVehicles?.slice(2);

        return (
          <Fragment>
            {shownLicenses &&
              shownLicenses?.map((car) => {
                return (
                  <p style={{ margin: 0 }}>
                    {car.endConsumerVehicleLicensePlate
                      ? car.endConsumerVehicleLicensePlate
                      : "-"}
                  </p>
                );
              })}
            <Fragment>
              {rest && rest.length > 0 ? (
                <Popover
                  style={{ padding: "5px" }}
                  placement="bottomLeft"
                  content={
                    <Fragment>
                      {rest.map((car) => {
                        return (
                          <p style={{ margin: "0 10px 0 10px" }}>{`${
                            car.endConsumerVehicleLicensePlate
                              ? car.endConsumerVehicleLicensePlate
                              : "-"
                          }`}</p>
                        );
                      })}
                    </Fragment>
                  }
                >
                  <p
                    style={{
                      margin: 0,
                      textDecoration: "underline",
                      color: "#2369BD",
                      fontSize: "12px",
                    }}
                  >{`+${rest.length}`}</p>
                </Popover>
              ) : null}
            </Fragment>
          </Fragment>
        );
      },
    },
    {
      title: "Último servicio",
      dataIndex: "lastAppointment",
      key: "lastAppointment",
      render: (_, { lastAppointment }) => {
        let name = "";
        let appointmentDateFormatted = "";
        if (lastAppointment?.id) {
          const serviceName =
            lastAppointment.services.length > 0
              ? lastAppointment.services[0].serviceTypeName
              : null;
          const packageName =
            lastAppointment.packages.length > 0
              ? lastAppointment.packages[0].servicePackageDesc
              : null;
          name = serviceName ? serviceName : packageName;
          appointmentDateFormatted = getHowLongAgo(
            lastAppointment.startDateTime
          );
        }
        return (
          <Fragment>
            {lastAppointment?.id && (
              <Fragment>
                <a
                  style={{
                    textDecoration: "underline",
                    color: "#2369BD",
                    fontSize: "14px",
                  }}
                >
                  {name}
                </a>
                <p style={{ fontSize: "14px" }}>{appointmentDateFormatted}</p>
              </Fragment>
            )}
          </Fragment>
        );
      },
    },
    {
      title: "Etiquetas",
      key: "customerTags",
      dataIndex: "customerTags",
      render: (_, { customerTags }) => {
        const shown = customerTags.slice(0, 3);
        const rest = customerTags.slice(3);
        return (
          <Fragment>
            {shown &&
              shown?.map((tag) => {
                return (
                  <Tag color="geekblue" key={tag.tagId}>
                    {tag.tagName}
                  </Tag>
                );
              })}
            {rest.length > 0 ? (
              <Popover
                content={
                  <Fragment>
                    {rest.map((tag) => {
                      return <p>{`${tag.tagName}`}</p>;
                    })}
                  </Fragment>
                }
              >
                <Tag color="geekblue" key="more-tag-01">
                  {`+${rest.length}`}
                </Tag>
              </Popover>
            ) : null}
          </Fragment>
        );
      },
    },
    {
      title: "",
      dataIndex: "customerId",
      key: "customerId",
      render: (item, row) => {
        return row.isHeader ? null : (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Popover
              zIndex={2}
              overlayInnerStyle={{ borderRadius: "10px" }}
              placement="bottomLeft"
              trigger="click"
              content={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                  }}
                >
                  <CkButton
                    style={{ width: "140px" }}
                    type="text"
                    // icon="edit"
                    onClick={() => {
                      setSelectedCustomer(row);
                      setOpenTagModal(true);
                    }}
                  >
                    Agregar etiqueta
                  </CkButton>
                  <CkButton
                    className="delete-table-action"
                    style={{ width: "140px", marginTop: 10 }}
                    type="text"
                    // icon="trash"
                    onClick={() => {
                      setSelectedCustomer(row);
                      setOpenDeleteModal(true);
                    }}
                    disabled={row.isCarkerClient}
                    color="secondary"
                  >
                    Borrar
                  </CkButton>
                </div>
              }
            >
              <CkButton
                type="text"
                icon="more-vertical"
                //id="ACCTSETUP008"
                //onClick={(ev) => gtmSetId(ev.currentTarget)}
              />
            </Popover>
          </div>
        );
      },
    },
  ];

  return columns;
};

const items: MenuProps["items"] = [
  {
    key: "ASCE",
    label: "Ascendente",
  },
  {
    key: "DESC",
    label: "Descendente",
  },
];

const ClientList: React.FC<IProps> = ({
  currentWorkshopId,
  data,
  setOpenClientCard,
  searchTerm,
  setSearchTerm,
  setSelectedCustomer,
  selectedCustomer,
  getClients,
}) => {
  const dispatch = useDispatch();
  const workshopTags = useSelector(getCurrentWorkshopTags);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showAddTagModal, setShowAddTagModal] = useState(false);
  const [addTagClientIds, setAddTagClientIds] = useState([]);
  const [showDeleteCustomerModal, setShowDeleteCustomerModal] = useState(false);
  const [isImportEnabled, setIsImportEnabled] = useState(false);
  const [showDescendingCustomers, setShowDescendingCustomers] = useState(false);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    if (data) {
      setCustomers(data);
    }
  }, [data]);

  const addTagToCustomer = async (selectedTags) => {
    console.log("adding tags: ", selectedTags);
    const customerId = selectedCustomer.customerId + 0;
    const customerIdArray = selectedRows.map((customer) => customer.customerId);
    console.log("to customer: ", customerId);
    selectedTags.map(async (tag) => {
      const res = await ClientsAPI.addTagToCustomer(
        currentWorkshopId,
        tag.tagId,
        selectedRows.length > 1 ? customerIdArray : [customerId]
      );
      console.log("res?[addTagToCustomer]: ", res.data);
    });
    dispatch(updateCustomerListFilterData({ filtersApplied: false }));
  };

  const onCreateTagClicked = async (newTag: string, setNewTag: Function) => {
    dispatch(
      addNewWorkshopTag({ workshopId: currentWorkshopId, tagName: newTag })
    );
    setNewTag("");
  };

  const sortData = (data: any[]) => {
    if (showDescendingCustomers) {
      return data.reverse();
    }
    return data;
  };

  return (
    <Fragment>
      <Row className="client-table-actions">
        <Col className="sub-search" span={12}>
          <Row className="search-container">
            <Input
              className="search-input-client-list"
              placeholder="Buscar por datos de cliente"
              prefix={<CkIcon name="search" />}
              style={{
                flex: 1,
                marginLeft: "8px",
                borderBottom: "1px solid",
              }}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <CkButton
              className="filter"
              type="primary"
              icon="order"
              onClick={() => setShowFilterModal(true)}
            >
              <p>Filtrar por</p>
            </CkButton>
            <Dropdown
              className="order"
              menu={{
                items,
                selectable: true,
                defaultSelectedKeys: ["ASCE"],
                onSelect: (e) => {
                  dispatch(updateCustomerListFilterData({ order: e.key }))
                },
              }}
            >
              <CkButton type="primary" icon="unfold">
                <p>Ordenar por</p>
              </CkButton>
            </Dropdown>
          </Row>
        </Col>
        <Col className="sub-actions" span={12}>
          <Row justify="end">
            <CkButton
              type="text"
              icon="import"
              onClick={() => setShowImportModal(true)}
              style={{ minWidth: "280px" }}
            >
              <p style={{ fontWeight: 400, margin: 0 }}>
                Importar lista de clientes existentes
              </p>
            </CkButton>
            <CkButton
              type="text"
              icon="export"
              onClick={() => setShowExportModal(true)}
              style={{ maxWidth: "100px" }}
            >
              <p style={{ fontWeight: 400, margin: 0 }}>Exportar</p>
            </CkButton>
          </Row>
        </Col>
      </Row>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "3%", margin: "10px" }}>
          <LetterDirectory
            onLetterClick={(letter: string) => {
              document.getElementById(`header-${letter}`)
                ? document.getElementById(`header-${letter}`).scrollIntoView()
                : null;
            }}
          />
        </div>
        <div style={{ width: "95%" }}>
          <Table
            sticky
            className="client-list-table"
            rowClassName="client-list-table-row"
            pagination={false}
            columns={getColumns(
              setOpenClientCard,
              setShowAddTagModal,
              setSelectedCustomer,
              setShowDeleteCustomerModal
            )}
            dataSource={customers}
            rowSelection={{
              type: "checkbox",
              hideSelectAll: true,
              onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: DataType[]
              ) => {
                setSelectedRows(selectedRows);
                setSelectedCustomer(selectedRows[0]);
                console.log(
                  `selectedRowKeys: ${selectedRowKeys}`,
                  "selectedRows: ",
                  selectedRows
                );
              },
              getCheckboxProps: (record: DataType) => ({
                disabled: record?.isHeader, // Column configuration not to be checked
                name: record.customerName,
              }),
            }}
            summary={() =>
              selectedRows.length > 1 ? (
                <Table.Summary fixed="top">
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={9}>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Row>
                          <Checkbox indeterminate={true} />
                          <p
                            style={{ paddingLeft: "5px", marginBottom: "7px" }}
                          >{`${selectedRows.length} clientes seleccionados`}</p>
                        </Row>
                        <Row>
                          <CkButton
                            type="text"
                            icon="tag"
                            size="small"
                            style={{ maxWidth: "180px" }}
                            onClick={() => {
                              setShowAddTagModal(true);
                            }}
                          >
                            Agregar etiqueta
                          </CkButton>
                          <CkButton
                            type="text"
                            icon="trash"
                            size="small"
                            style={{ maxWidth: "80px" }}
                          >
                            Borrar
                          </CkButton>
                        </Row>
                      </div>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              ) : null
            }
          />
        </div>
      </div>
      <CkModal
        className="client-list-filter-modal"
        open={showFilterModal}
        onCancel={() => setShowFilterModal(false)}
      >
        <ClientListFilter
          tags={workshopTags}
          onFilterSave={()=>{
            dispatch(updateCustomerListFilterData({ filtersApplied: true }));
            setShowFilterModal(false);
          }}
          onFilterReset={() => {
            dispatch(resetCustomerListFilter());
            dispatch(updateCustomerListFilterData({ filtersApplied: false }));
            setShowFilterModal(false);
          }}
        />
      </CkModal>
      <ImportClientsList showImportModal={showImportModal} setShowImportModal={setShowImportModal} onFinish={() => getClients()} />
      <ExportClientsList 
        showExportModal={showExportModal}
        setShowExportModal={setShowExportModal}
        data={data}
      />
      <CkModal
        className="add-tags-on-client-list-modal"
        open={showAddTagModal}
        onCancel={() => setShowAddTagModal(false)}
      >
        <AddNewTagForm
          tags={[]} //current tags
          setOpen={setShowAddTagModal}
          onCreateTagClicked={onCreateTagClicked}
          onSaveClicked={addTagToCustomer}
        />
      </CkModal>
      <CkModal
        open={showDeleteCustomerModal}
        onCancel={() => setShowDeleteCustomerModal(false)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p>¿Estás seguro que quieres borrar este cliente?</p>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <CkButton
              onClick={async () => {
                await ClientsAPI.deleteClient(
                  currentWorkshopId,
                  selectedCustomer.customerId
                );
                setShowDeleteCustomerModal(false);
                window.location.reload();
              }}
            >
              Borrar
            </CkButton>
            <CkButton
              type="text"
              onClick={() => setShowDeleteCustomerModal(false)}
            >
              Cancelar
            </CkButton>
          </div>
        </div>
      </CkModal>
    </Fragment>
  );
};

export default memo(ClientList);
