import React, { memo, useState } from "react";
import "./style.css";

interface IProps {
  onLetterClick: Function;
}
const LetterDirectory: React.FC<IProps> = ({  onLetterClick }: IProps) => {
  const [activeLetter, setActiveLetter] = useState("A")

  const clickAction = (letter) => {
    setActiveLetter(letter);
    onLetterClick(activeLetter);
  }

  return (
    <div className="letter-container">
      <p onClick={()=> clickAction("A")} className={ activeLetter.toUpperCase() === "A"? "active-letter contact-initial-letter" : "contact-initial-letter"}>A</p>
      <p onClick={()=> clickAction("B")} className={ activeLetter.toUpperCase() === "B"? "active-letter contact-initial-letter" : "contact-initial-letter"}>B</p>
      <p onClick={()=> clickAction("C")} className={ activeLetter.toUpperCase() === "C"? "active-letter contact-initial-letter" : "contact-initial-letter"}>C</p>
      <p onClick={()=> clickAction("D")} className={ activeLetter.toUpperCase() === "D"? "active-letter contact-initial-letter" : "contact-initial-letter"}>D</p>
      <p onClick={()=> clickAction("E")} className={ activeLetter.toUpperCase() === "E"? "active-letter contact-initial-letter" : "contact-initial-letter"}>E</p>
      <p onClick={()=> clickAction("F")} className={ activeLetter.toUpperCase() === "F"? "active-letter contact-initial-letter" : "contact-initial-letter"}>F</p>
      <p onClick={()=> clickAction("G")} className={ activeLetter.toUpperCase() === "G"? "active-letter contact-initial-letter" : "contact-initial-letter"}>G</p>
      <p onClick={()=> clickAction("H")} className={ activeLetter.toUpperCase() === "H"? "active-letter contact-initial-letter" : "contact-initial-letter"}>H</p>
      <p onClick={()=> clickAction("I")} className={ activeLetter.toUpperCase() === "I"? "active-letter contact-initial-letter" : "contact-initial-letter"}>I</p>
      <p onClick={()=> clickAction("J")} className={ activeLetter.toUpperCase() === "J"? "active-letter contact-initial-letter" : "contact-initial-letter"}>J</p>
      <p onClick={()=> clickAction("K")} className={ activeLetter.toUpperCase() === "K"? "active-letter contact-initial-letter" : "contact-initial-letter"}>K</p>
      <p onClick={()=> clickAction("L")} className={ activeLetter.toUpperCase() === "L"? "active-letter contact-initial-letter" : "contact-initial-letter"}>L</p>
      <p onClick={()=> clickAction("M")} className={ activeLetter.toUpperCase() === "M"? "active-letter contact-initial-letter" : "contact-initial-letter"}>M</p>
      <p onClick={()=> clickAction("N")} className={ activeLetter.toUpperCase() === "N"? "active-letter contact-initial-letter" : "contact-initial-letter"}>N</p>
      <p onClick={()=> clickAction("O")} className={ activeLetter.toUpperCase() === "O"? "active-letter contact-initial-letter" : "contact-initial-letter"}>O</p>
      <p onClick={()=> clickAction("P")} className={ activeLetter.toUpperCase() === "P"? "active-letter contact-initial-letter" : "contact-initial-letter"}>P</p>
      <p onClick={()=> clickAction("Q")} className={ activeLetter.toUpperCase() === "Q"? "active-letter contact-initial-letter" : "contact-initial-letter"}>Q</p>
      <p onClick={()=> clickAction("R")} className={ activeLetter.toUpperCase() === "R"? "active-letter contact-initial-letter" : "contact-initial-letter"}>R</p>
      <p onClick={()=> clickAction("S")} className={ activeLetter.toUpperCase() === "S"? "active-letter contact-initial-letter" : "contact-initial-letter"}>S</p>
      <p onClick={()=> clickAction("T")} className={ activeLetter.toUpperCase() === "T"? "active-letter contact-initial-letter" : "contact-initial-letter"}>T</p>
      <p onClick={()=> clickAction("U")} className={ activeLetter.toUpperCase() === "u"? "active-letter contact-initial-letter" : "contact-initial-letter"}>U</p>
      <p onClick={()=> clickAction("V")} className={ activeLetter.toUpperCase() === "V"? "active-letter contact-initial-letter" : "contact-initial-letter"}>V</p>
      <p onClick={()=> clickAction("W")} className={ activeLetter.toUpperCase() === "W"? "active-letter contact-initial-letter" : "contact-initial-letter"}>W</p>
      <p onClick={()=> clickAction("X")} className={ activeLetter.toUpperCase() === "X"? "active-letter contact-initial-letter" : "contact-initial-letter"}>X</p>
      <p onClick={()=> clickAction("Y")} className={ activeLetter.toUpperCase() === "Y"? "active-letter contact-initial-letter" : "contact-initial-letter"}>Y</p>
      <p onClick={()=> clickAction("Z")} className={ activeLetter.toUpperCase() === "Z"? "active-letter contact-initial-letter" : "contact-initial-letter"}>Z</p>
    </div>
  );
};

export default memo(LetterDirectory);
