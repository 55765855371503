import React, { memo, useState } from "react";
import "./style.css";
import { CkButton, CkIcon } from "../../../../../CkUI";

interface IProps {
  id: string,
  text: string,
  onClose?: Function,
  selected: boolean,
  onFilterClick: Function,
}
const FilterItem: React.FC<IProps> = ({ id, text, onClose, selected, onFilterClick }: IProps) => {

  return (
    <div className={selected? "filter-item selected": "filter-item"} onClick={() => onFilterClick(id)}>
      <CkIcon name="circle-add" />
      <p style={{ margin: 0, paddingLeft: "2px" }}>{text}</p>
      {
        onClose && (
          <CkButton type="text" icon="close-no-background" />
        )
      }
    </div>
  );
};

export default memo(FilterItem);
