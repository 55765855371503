import React, {Fragment, memo, useEffect, useState} from "react";
import {Avatar, Col, Divider, Drawer, Form, Row, Table, Tag} from "antd";

import { CkButton, CkIcon } from "../../../CkUI";
import { UserOutlined } from "@ant-design/icons";
import NamedCardItem from "./NamedCardItem";
import type {ColumnsType} from "antd/es/table";
import {ClientsAPI} from "../../../app/Services/axios";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentWorkshop} from "../../../stores";
import {setCustomerFormData, updateCustomerFormData} from "../../../stores/Slice/NewCustomerFormSlice";
import moment from "moment";

interface IProps {
  data: any;
  open: boolean;
  setOpen: Function;
  onClose: Function;
  openEdit: Function;
}

interface DataType {
  date: string;
  status?: string;
  service: string;
  amount?: string;
  id: string;
  vehicle: any;
  receptionUrl?: string;
  quotationUrl?: boolean;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Fecha',
    dataIndex: 'startDateTime',
    key: 'startDateTime',
    render: (item, row) => {
      return item.toString().slice(0, 10);
    }
  },
  {
    title: 'Estatus',
    dataIndex: 'statusName',
    key: 'statusName',
  },
  {
    title: 'Servicios',
    dataIndex: 'packages',
    key: 'packages',
    render: (item, row) => {
      if(item.length > 0) {
        return item[0].servicePackageDesc;
      }
      return "-";
    }
  },
  {
    title: 'Monto',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Vehículo',
    dataIndex: 'vehicle',
    key: 'vehicle',
    render: (item, row) => {
      return `${item.brandName} ${item.modelName} ${item.year}`
    }
  },
];

const AddClientDrawer: React.FC<IProps> = ({ data, open, setOpen, onClose, openEdit }: IProps) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const currentWorkshop: any = useSelector(getCurrentWorkshop);
  const [customerData, setCustomerData] = useState(null);

  useEffect(() => {
    console.log("data@AddClientDrawer: ", data);
    const fetchCustomerData = async () => {
      const fetchedCustomerData = await ClientsAPI.getClient(currentWorkshop.id, data.customerId);
      setCustomerData(fetchedCustomerData.data);
      console.log("fetchedCustomerData[shouldBeUpdateSplice]: ", fetchedCustomerData.data);
    }
    if(open) fetchCustomerData();
  }, [data]);

  useEffect(() => {

  }, []);

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const getColor = (status: string) => {
    switch (status) {
      case "Cita pendiente":
        return "gold";
      case "Cita en progreso":
        return "green";
      case "Recordatorio enviado":
        return "blue";
      case "Sin cita activa":
        return "default";
      case "Cita cancelada":
        return "red";
      default:
        return "default";
    }
  }

  const getStatus = (lastAppointment) => {
    switch (lastAppointment?.statusCode || "") {
      case "CREAT":
      case "APPO":
      case "RECEP":
      case "DIAG":
      case "QUOT":
      case "PROG":
      case "READY":
        return "Cita en progreso"
      case "PEND":
        return "Cita pendiente"
      case "CLOS":
        return "Sin cita activa"
      case "CANCE":
        return "Cita cancelada"
      default:
        return "Sin cita activa";
    }
  }

  const getUserStatusTag = (text) => {
    return (<Tag color={getColor(text)}>{text}</Tag>);
  }

  const getAppointmentsTotal = () => {
    let total = 0;
    customerData?.appointments && customerData?.appointments.map((app)=> {
      if(app?.packages.length > 0) {
        total += app?.packages[0].price;
      }
    });
    return total;
  }

  const getGenderWord = (genderLetter) => {
    switch (genderLetter) {
      case "M":
        return "Hombre";
      case "F":
        return "Mujer";
      case "O":
        return "Otro";
      case "N":
        return "Prefiere no contestar";
      default:
        return "-";
    }
  }

  return (
    <Drawer
      className="add-client-drawer"
      title={
        <Fragment />
      }
      placement="right"
      width="90%"
      open={open}
      closeIcon={<CkIcon name="caret-left-large"/> }
      onClose={() => onClose()}
      style={{ zIndex: 2147483640 }}
    >
      <div style={{ padding: "0 4% 15px 6%" }}>
        <Row style={{ margin: "0 0 15px 0"}}>
          <Col span={2}>
            <Avatar shape="square" size={64} icon={<UserOutlined />} style={{ margin: "0 5px 0 0"}}/>
          </Col>
          <Col span={4}>
            <p style={{ color: "#2369BD", fontSize: "24px", margin: 0}}>{`${data?.customerName} ${data?.customerLastName}`}</p>
            {getUserStatusTag("Sin cita activa")}
          </Col>
          <Col span={18}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
              <CkButton
                icon="pencil"
                type="text"
                onClick={() => {
                  setOpen(false);
                  // update slice to the data of the customer to update
                  dispatch(updateCustomerFormData({...customerData}));
                  openEdit();
                }}
              >
                Editar datos
              </CkButton>
            </div>
          </Col>
        </Row>

        <Row style={{ margin: "0 0 15px 0"}}>
          <Col span={24}>
            <p style={{ fontSize: "16px", color: "#001450"}}>
              Datos de contacto
            </p>
          </Col>

          <Col span={8}>
            <NamedCardItem itemName="Teléfono celular" item={data?.customerTel || "-"}/>
          </Col>

          <Col span={8}>
            <NamedCardItem itemName="Correo electrónico" item={data?.customerEmail|| "-"} />
          </Col>

        </Row>

        <Row style={{ margin: "0 0 15px 0"}}>
          <Col span={24}>
            <p style={{fontSize: "16px", color: "#001450"}}>Datos complementarios</p>
          </Col>

          <Col span={8}>
            <NamedCardItem itemName="Código postal" item={data?.customerZipCode|| "-"} />
          </Col>

          <Col span={8}>
            <NamedCardItem itemName="Fecha de nacimiento" item={data?.customerDateOfBirth? moment(data?.customerDateOfBirth).format('L') : "-"} />
          </Col>

          <Col span={8} />

          <Col span={8}>
            <NamedCardItem itemName="Dirección de recolección" item={data?.customerAddress|| "-"} />
          </Col>

          <Col span={8}>
            <NamedCardItem itemName="Notas" item={data?.customerNotes|| "-"} />
          </Col>

          <Col span={8} />

          <Col span={8}>
            <NamedCardItem itemName="Género" item={getGenderWord(data?.customerSex)} />
          </Col>

        </Row>

        <Row style={{margin: "0 0 15px 0"}}>
          <Col span={24}>
            <p style={{fontSize: "16px", color: "#001450"}}>Datos fiscales</p>
          </Col>

          <Col span={8}>
            <NamedCardItem itemName="Nombre del receptor" item={data?.customerReceptorName|| "-"} />
          </Col>

          <Col span={8}>
            <NamedCardItem itemName="RFC" item={data?.customerRFC|| "-"} />
          </Col>

          <Col span={8} />

          <Col span={8}>
            <NamedCardItem itemName="Régimen fiscal" item={data?.customerTaxRegime|| "-"} />
          </Col>

          <Col span={8}>
            <NamedCardItem itemName="Uso de CFDI" item={data?.customerCFDI|| "-"} />
          </Col>

        </Row>

        <Row style={{margin: "0 0 15px 0"}}>
          <Row>
            <p style={{ fontSize: "16px", color: "#001450", margin: 0}}>Etiquetas</p>
            <CkIcon name="information-round" style={{ margin: "0 5px 0 5px"}}/>
          </Row>

          <Row>
            {
              data?.customerTags && data?.customerTags.map((tag, index) => {
                return (<Tag color='geekblue' key={index} style={{ width: "fit-content"}}>
                  {tag.tagName}
                </Tag>)
              })
            }
          </Row>

        </Row>

        <Row style={{ margin: "0 0 15px 0"}}>
          <p style={{ fontSize: "16px", color: "#001450", margin: 0}}>
            Vehículos
          </p>
        </Row>

        {
          data?.customerVehicles && data?.customerVehicles.map((v, index) => {
            return <Tag key={index}>{`${v.endConsumerVehicleBrand} ${v.endConsumerVehicleModel} ${v.endConsumerVehicleYear}`}</Tag>
          })
        }

        <Row style={{margin: "20px 0 15px 0"}}>
          <Col span={24}>
            <p style={{color: "#001450", fontSize: "20px", fontWeight: 600}}>
              Historial de citas
            </p>
          </Col>

          {
            data?.appointments && data?.appointments.length < 1 ? (
              <Col span={24}>
                <p style={{fontSize: "16px", fontWeight: 600, color: "#404040"}}>
                  Este cliente áun no tiene citas registradas en tu taller.
                </p>
              </Col>
            ) : (
              <Fragment>
                <Col span={24}>
                  <div style={{display: "flex", flexDirection: "row"}}>
                    <div
                      style={{
                        width: "200px",
                        height: "145px",
                        backgroundColor: "#F9F9F9",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <p style={{fontSize: "64px", color: "#001450", fontWeight: 600, margin: 0}}>
                        {customerData?.appointments?.length}
                      </p>
                      <p style={{fontSize: "20px", color: "#001450", margin: 0}}>
                        Total de visitas
                      </p>
                    </div>
                    <div
                      style={{
                        height: "145px",
                        width: "100%",
                        border: "1px solid #EDEDED",
                        margin: "0 0 0 15px",
                        padding: "10px"
                      }}
                    >
                      <p style={{ fontSize: "20px", color: "#001450", margin: 0}}>
                        Total gastado a la fecha
                      </p>
                      <p style={{ fontSize: "64px", fontWeight: 600, color: "#001450", margin: 0}}>
                        {
                          `$${getAppointmentsTotal()}`
                        }
                      </p>
                    </div>
                  </div>
                </Col>

                <Col span={24} style={{ margin: "25px 0 0 0"}}>
                  <Table
                    columns={columns}
                    dataSource={customerData?.appointments || []}
                  />

                </Col>
              </Fragment>

            )
          }
        </Row>
      </div>
    </Drawer>
  );
};

export default memo(AddClientDrawer);
